html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
}

.header {
    width: 100%;
    height: 60px;
    padding: 8px 8px 8px 16px;
    box-sizing: border-box;
    background: #00a5e4;
    font-size: 1.2em;
    line-height: 44px;
    color: white;
}

#viewer {
    width: 100%;
    height: 100%;
}